<template>
  <div id="home">
    <AppContainer :useBackground="true" :useShadow="true">
      <ModalSuggest v-if="suggest" @closeModalSuggestUser="suggest = false" />
      <div v-if="user_info && !suggest">
        <div
          ref="header"
          class="d-flex justify-content-center align-items-center home__div m-auto"
          style="position: fixed; top: 0; right: 0; left: 0"
        >
          <div class="d-flex justify-content-between align-items-center header">
            <img
              src="@/assets/image/nav_icon/sliders.svg"
              @click="openSearch()"
            />
            <div>
              <img
                class="logo"
                alt="logo-prego"
                src="@/assets/image/logo-prego.png"
              />
            </div>
            <div
              class="position-relative icon--right"
              @click="$router.push({ name: 'Notification' })"
            >
              <img src="@/assets/image/nav_icon/bell.svg" />
              <div
                class="
                  number-notification
                  position-absolute
                  d-flex
                  justify-content-center
                  align-items-center
                "
                :class="
                  numberNotification >= 100 ? 'number-notification-long' : ''
                "
                v-if="numberNotification > 0"
              >
                <span class="f-w3">{{ numberNotification }}</span>
              </div>
            </div>
          </div>
        </div>
        <NotifyHeader />
        <div id="content-scroll" class="content-scroll container">
          <template v-if="isLoading">
            <AppLoading />
          </template>
          <template v-else>
            <div
              class="home"
              id="home_content"
              v-if="user.registration_status != 1"
            >
              <div class="component component-header">
                <!-- <div class="title component-header__title">
                  <p class="mb-0 text-left title__p f-w6">おすすめ検索</p>
                </div> -->
                <div class="d-flex recommend">
                  <div
                    v-for="(option, index) in recommend"
                    :key="index"
                    class="recommend__option"
                    :class="{
                      'recommend__option-golf': option.id == 1,
                      'recommend__option-age': option.id == 2,
                      'recommend__option-last':
                        option.id == 4 || option.id == 5,
                      'recommend__option-finish': option.id == 6
                    }"
                    @click="changeFilter(option.id)"
                  >
                    <div
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        recommend__option__img
                        position-relative
                      "
                    >
                      <img
                        :src="
                          require('@/assets/image/icon_home/outline/' +
                            option.imgSrc)
                        "
                        class="recommend__img"
                        :class="{
                          'recommend__img-age': option.id == 2
                        }"
                      />
                      <img
                        :src="
                          require('@/assets/image/icon_home/fill/' +
                            option.imgSrcActive)
                        "
                        class="
                          recommend__img recommend__img-active
                          position-absolute
                        "
                        :class="{
                          'recommend__img-age': option.id == 2,
                          'recommend__img-rank':
                            option.id == 3 || option.id == 5 || option.id == 1
                        }"
                      />
                      <div
                        v-if="
                          option.text === '足跡' && totalNotReadFootprint > 0
                        "
                        class="number-not-read position-absolute f-w3"
                        :class="
                          totalNotReadFootprint >= 100
                            ? 'notify-round'
                            : 'notify-circle'
                        "
                      >
                        {{ totalNotReadFootprint }}
                      </div>
                      <!-- <div
                        v-if="
                          option.text === 'Superオファー状況' &&
                            totalNotReadAds['apply'] > 0
                        "
                        class="notify-ads position-absolute f-w3"
                      ></div> -->
                    </div>
                    <p class="p--margin-top">{{ option.text }}</p>
                  </div>
                </div>
              </div>
              <div class="component position-relative">
                <div class="title">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <p class="mb-0 text-left title__p f-w6">本日のPICKUP</p>
                    </div>
                    <div class="text-right">
                      <p class="mb-0 all" @click="nextCastSearch(2)">
                        すべて
                        <b-icon icon="chevron-right" variant="dark"></b-icon>
                      </p>
                    </div>
                  </div>
                </div>
                <template v-if="usersPickUp.length > 4">
                  <div
                    class="position-absolute icon-slider icon-slider-next"
                    @click="scroll()"
                    v-if="!isShowSlider"
                  >
                    <img src="@/assets/image/icon_home/next.svg" alt="" />
                  </div>
                  <div
                    v-else
                    class="position-absolute icon-slider icon-slider-pre"
                    @click="pre()"
                  >
                    <img src="@/assets/image/icon_home/next.svg" alt="" />
                  </div>
                </template>
              </div>
              <div
                class="pickup"
                v-if="usersPickUp.length > 0"
                id="pickup-user"
              >
                <user-summary
                  v-for="(user, index) in usersPickUp"
                  :key="index"
                  :propsUser="user"
                  :propsSex="2"
                  :propsType="typeScroll"
                  :propsIndex="index"
                  @update-like="changeLikeStatus($event)"
                />
              </div>
              <div v-else class="empty-data">
                <img
                  src="~@/assets/image/icon_profile/icon-empty-data.svg"
                  alt=""
                />
                <div>現在データはありません。</div>
              </div>
              <div class="component component__bottom">
                <div class="title">
                  <div
                    class="d-flex justify-content-between align-items-center"
                    id="popular-m-user"
                  >
                    <div>
                      <p class="mb-0 text-left title__p f-w6">
                        人気のお相手
                      </p>
                    </div>
                    <div class="text-right">
                      <!--                      <p class="mb-0 all" @click="nextCastSearch(3)">-->
                      <!--                        すべて-->
                      <!--                        <b-icon icon="chevron-right" variant="dark"></b-icon>-->
                      <!--                      </p>-->
                    </div>
                  </div>
                </div>
                <div class="row popular" v-if="usersPopular.length > 0">
                  <user-summary
                    v-for="(user, index) in usersPopular"
                    :key="index"
                    :propsUser="user"
                    :propsType="typeMulti"
                    :propsSex="2"
                    @update-like="changeLikeStatusPopular"
                  />
                </div>
                <div v-else class="empty-data">
                  <img
                    src="~@/assets/image/icon_profile/icon-empty-data.svg"
                    alt=""
                  />
                  <div>現在データはありません。</div>
                </div>
                <div
                  class="paginate-popular"
                  v-if="
                    usersPopular.length > 0 && popularTotal > limitPopularPage
                  "
                >
                  <Pagination
                    :currentPage="currentPage"
                    :total="popularTotal"
                    :limit="limitPopularPage"
                    @selectedPage="handlePageClick"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <card-footer ref="footer" />
      </div>
    </AppContainer>
    <ModalFilterCast
      ref="modalFilterCast"
      :name="'絞り込み検索'"
      @close-popup="closePopup()"
    >
      <FilterCastPopup @searchCast="searchCast($event)" />
    </ModalFilterCast>
    <ModalLayout ref="creditPopup" :defaultStyle="false">
      <createOrderPopup @closeModal="$refs.creditPopup.closeModal()" />
    </ModalLayout>
    <ModalLayout ref="creditIdentifyPopup" :defaultStyle="false">
      <IdentifyCrefitCardPopup
        @closeModal="$refs.creditIdentifyPopup.closeModal()"
      />
    </ModalLayout>
    <ModalLayout ref="identifyPopup" :defaultStyle="false">
      <IdentifyPopup
        @identify="identify"
        :identity_status="user_info.identity_status"
        :sex="user_info.sex"
      />
    </ModalLayout>
    <ModalLayout ref="addMonthlyPlanPopup">
      <AddMonthlyPlanPopup
        @close-modal="closeAddMonthlyPlanPopup"
        :routePage="'NotMessage'"
      />
    </ModalLayout>
    <!-- <ModalFilterCast
      ref="modal-suggest-user"
      :defaultStyle="false"
      :header="false"
    >
      
    </ModalFilterCast> -->
    <ModalInviteRateRoundLog ref="modal-invite-rate">
      <div class="invite f-w3">
        <img
          class="invite__img"
          src="@/assets/image/roundlog/invite-rate.svg"
        />
        <p class="invite__p invite__p__highlight f-w6 text-center mb-0">
          ラウンドお疲れ様でした！
        </p>
        <p class="invite__p f-w3 text-center mb-0">
          ラウンドログを作成し、お相手にメッセージを送りましょう！
        </p>
        <button class="invite__button" @click="redirectToMessageGroup()">
          次へ
        </button>
      </div>
    </ModalInviteRateRoundLog>
  </div>
</template>

<script>
// import $ from "jquery";
import NotifyHeader from "@/components/NotifyHeader.vue";
import CardFooter from "@/components/CardFooter.vue";
import UserSummary from "@/components/UserSummary.vue";
// import TutorialHome from "@/components/Men/Tutorial/TutorialHome";
import { DEFAULT_DIFFERENCE } from "@/definition/constants";
import FilterCastPopup from "../../components/FilterCastPopup";
import ModalFilterCast from "@/views/Common/ModalFilterCast";
import service from "@/utils/axios";
import { USER_URL } from "@/api/api";
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
import createOrderPopup from "@/components/CreateOrderPopup.vue";
import IdentifyCrefitCardPopup from "@/components/IdentifyCrefitCardPopup.vue";
import IdentifyPopup from "@/components/IdentifyPopup.vue";
import { PREGO_LINE_ID } from "@/utils/const";
import ModalInviteRateRoundLog from "@/views/Common/ModalInviteRateRoundLog";
import ModalSuggest from "@/views/Common/DistanceView";
import AddMonthlyPlanPopup from "@/components/AddMonthlyPlanPopup.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Home",
  components: {
    "card-footer": CardFooter,
    "user-summary": UserSummary,
    ModalFilterCast,
    FilterCastPopup,
    createOrderPopup,
    IdentifyCrefitCardPopup,
    IdentifyPopup,
    ModalInviteRateRoundLog,
    NotifyHeader,
    ModalSuggest,
    AddMonthlyPlanPopup,
    Pagination
  },
  metaInfo() {
    return {
      title: "ホームページ",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading",
      totalNotReadFootprint: "auth/totalNotReadFootprint",
      user_info: "auth/user",
      cardInfo: "payment-stripe/cardInfo",
      limit: "areaLimit/limit_area",
      totalNotReadAds: "auth/totalNotReadAds",
      monthlyPlanStatus: "payment-stripe/monthlyPlanStatus"
    })
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await store.dispatch("auth/getInfoUser");
    const user = store.getters["auth/user"];
    if (user.registration_status == 1) {
      if (!localStorage.getItem("step")) {
        localStorage.removeItem("step");
        localStorage.setItem("step", 1);
      }
      this.$router.push({ name: "RegisterProfile" });
    } else if (user.registration_status == 2 || user.registration_status == 3) {
      localStorage.removeItem("step");
      if (
        this.$route.params.showPopup ||
        !localStorage.getItem("showUserSuggest")
      ) {
        await this.$store
          .dispatch("userApp/getNumberSuggestUsers")
          .then(res => {
            this.totalUser = res.data.data;
          });
        if (this.totalUser && this.user_info.prefecture_master_id) {
          this.$nextTick(() => {
            this.suggest = true;
            localStorage.setItem("showUserSuggest", true);
          });
        } else {
          this.$toast("現在おすすめゴルファーはありません", "通知", "danger");
          await this.getListUsersPickup();
          await this.getListUsersPopular(this.currentPage);
        }
      } else {
        await this.getListUsersPickup();
        await this.getListUsersPopular(this.currentPage);
      }
    }
    //
    this.$store.dispatch("auth/getTotalNotReadFootprint");
    this.$store.dispatch("common/setIsLoading", false);
    await this.getPaymentMonthPacket();
  },
  data() {
    return {
      typeScroll: { type: "scroll" },
      typeMulti: { type: "multi" },
      isShowTutorial: true,
      filterSelected: 0,
      numberNotification: null,
      user: null,
      PREGO_LINE_ID: PREGO_LINE_ID,
      recommend: [
        {
          text: "おすすめ",
          imgSrc: "icon-distance.svg",
          imgSrcActive: "icon-distance.svg",
          id: 7
        },
        {
          text: "ゴルフレベル",
          imgSrc: "golf-rank.svg",
          imgSrcActive: "golf-rank.svg",
          id: 0
        },
        {
          text: "エリア",
          imgSrc: "area.svg",
          imgSrcActive: "area.svg",
          id: 1
        },
        // {
        //   text: "年齢",
        //   imgSrc: "age.svg",
        //   imgSrcActive: "age.svg",
        //   id: 2
        // },
        {
          text: "Superオファー",
          imgSrc: "order-list.svg",
          imgSrcActive: "order-list.svg",
          id: 3
        },
        // {
        //   text: "ランキング",
        //   imgSrc: "rank.svg",
        //   imgSrcActive: "rank.svg",
        //   id: 4
        // },
        {
          text: "足跡",
          imgSrc: "track.svg",
          imgSrcActive: "track.svg",
          id: 5
        },
        {
          text: "お気に入り",
          imgSrc: "favorite.svg",
          imgSrcActive: "favorite.svg",
          id: 6
        }
      ],
      step: null,
      usersPickUp: [],
      usersPopular: [],
      avg_score_lower: null,
      avg_score_upper: null,
      golf_area_ids: null,
      age_lower: null,
      age_upper: null,
      isShowSlider: false,
      invite: null,
      openModalSearch: false,
      totalUser: 0,
      suggest: false,
      currentPage: 1,
      limitPopularPage: 100,
      popularTotal: 0
    };
  },
  mounted: function() {
    let touchStart, touchEnd;
    let self = this;
    let elements = document.getElementById("home");
    elements.addEventListener("touchstart", swipe);
    elements.addEventListener("touchend", swipe);
    elements.addEventListener("touchmove", swipe);

    function swipe(event) {
      if (event.type == "touchstart") {
        touchStart = event.targetTouches[0].clientY;
      }
      if (event.type == "touchmove") {
        touchEnd = event.targetTouches[0].clientY;
      }
      if (
        document.getElementById("home_content") &&
        document.getElementById("home_content").getBoundingClientRect().top >=
          63 &&
        !document.getElementById("filter-cast")
      ) {
        if (event.type == "touchend") {
          if (touchEnd - touchStart >= 150) {
            self.$root.$refs.loading.start();
            window.location.reload();
          }
        }
      }
      if (event.type == "touchend") {
        touchEnd = 0;
      }
    }
    if (localStorage.getItem("ad_room_" + this.user_info.user_id)) {
      this.$refs["modal-invite-rate"].openModal();
    }
  },
  methods: {
    ...mapActions("payment-stripe", ["getPaymentMonthPacket"]),
    resetAddFriendPrego() {
      localStorage.setItem("step", 2);
      this.$router.push({ name: "MenMyPageEditProfile", params: { id: 1 } });
    },
    addFriendPrego() {
      window.open("https://line.me/R/ti/p/" + process.env.VUE_APP_LINE_ID);
      localStorage.setItem("step", 2);
      this.$router.push({ name: "MenMyPageEditProfile", params: { id: 1 } });
    },
    openSearch() {
      this.$refs.modalFilterCast.openModal();
    },
    closePopup() {
      this.reportWindowSize();
    },
    reportWindowSize() {
      document.getElementById("content-scroll").focus();
      if (window.innerWidth < 768) {
        const vh = Math.max(
          document.documentElement.clientHeight || 0,
          window.innerHeight || 0
        );

        document.getElementById("content-scroll").style.height = vh + "px";
      }
    },
    pre() {
      document.getElementById("pickup-user").scrollLeft -= 500;
      this.isShowSlider = false;
    },
    scroll() {
      document.getElementById("pickup-user").scrollLeft += 500;
      this.isShowSlider = true;
    },
    searchCast(event) {
      this.$refs["modalFilterCast"].closeModal();
      this.$router.push({
        name: "CastSearch",
        query: {
          page: event.page,
          type: event.type,
          avg_score_lower: event.avg_score_lower,
          avg_score_upper: event.avg_score_upper,
          age_lower: event.age_lower,
          age_upper: event.age_upper,
          golf_area_ids: event.golf_area_ids,
          best_score_upper: event.best_score_upper,
          best_score_lower: event.best_score_lower,
          meeting_method: event.meeting_method,
          appearance_ids: event.appearance_ids,
          occupation_ids: event.occupation_ids,
          single_participant: event.single_participant,
          female_golf_rank_ids: event.female_golf_rank_ids,
          prefecture_ids: event.prefecture_ids,
          area_id: event.area_id,
          distance_lower: event.distance_lower,
          distance_upper: event.distance_upper,
          ["30_minutes_pay_lower"]: event["30_minutes_pay_lower"],
          ["30_minutes_pay_upper"]: event["30_minutes_pay_upper"]
        }
      });
    },
    async getListUsersPickup() {
      const params = {
        page: 1,
        type: 2,
        perpage: 5,
        golf_area_ids: this.golf_area_ids
      };
      await this.$store
        .dispatch("userApp/getListUsers", params)
        .then(response => {
          this.usersPickUp = response.data.users;
          this.numberNotification = response.data.numberNotiUnread;
        });
      this.user = this.$store.getters["auth/user"];
    },
    async getListUsersPopular(page) {
      const params = {
        page: page,
        type: 3,
        perpage: this.limitPopularPage,
        golf_area_ids: this.golf_area_ids
      };
      await this.$store
        .dispatch("userApp/getListUsers", params)
        .then(response => {
          this.usersPopular = response.data.users;
          this.popularTotal = response.data.total;
        });
    },
    async closeAddMonthlyPlanPopup() {
      this.$refs.addMonthlyPlanPopup.closeModal();
    },
    checkIdentityCreditCard() {
      if (!this.monthlyPlanStatus) {
        this.$refs.addMonthlyPlanPopup.openModal();
      } else {
        this.$root.$refs.loading.start();
        this.$router.push({
          name: "MenOrderList"
        });
        this.$root.$refs.loading.finish();
      }
    },
    identify() {
      if (this.$route.name != "MenMyPageIdentifyProfile")
        this.$router.push({ name: "MenMyPageIdentifyProfile" });
      this.$refs.identifyPopup.closeModal();
      return;
    },
    async changeFilter(key) {
      this.filterSelected = key;
      //click change page
      switch (this.filterSelected) {
        case 3:
          if (this.limit == 0) {
            this.checkIdentityCreditCard();
          }
          break;
        case 0:
          this.$root.$refs.loading.start();
          await this.$router.push({
            name: "CastSearch",
            query: {
              type: 1,
              page: 1,
              avg_score_lower: Number(this.user.avg_score) - DEFAULT_DIFFERENCE,
              avg_score_upper: Number(this.user.avg_score) + DEFAULT_DIFFERENCE
            }
          });
          this.$root.$refs.loading.finish();
          this.$toast("スコアが近い人が表示されました", "通知", "success");
          break;
        case 1:
          this.$root.$refs.loading.start();
          await this.$router.push({
            name: "CastSearch",
            query: {
              type: 1,
              page: 1,
              golf_area_ids:
                this.user.golf_area_ids.length > 0
                  ? this.user.golf_area_ids.toString()
                  : null
            }
          });
          this.$toast(
            "ピックアップエリアが近い人が表示されました",
            "通知",
            "success"
          );
          this.$root.$refs.loading.finish();
          break;
        case 2:
          this.$root.$refs.loading.start();
          await this.$router.push({
            name: "CastSearch",
            query: {
              page: 1,
              type: 1,
              age_lower:
                Number(this.user.user_age) - DEFAULT_DIFFERENCE > 0
                  ? Number(this.user.user_age) - DEFAULT_DIFFERENCE
                  : 1,
              age_upper: Number(this.user.user_age) + DEFAULT_DIFFERENCE
            }
          });
          this.$toast("年齢が近い人が表示されました", "通知", "success");
          this.$root.$refs.loading.finish();
          break;
        case 4:
          this.$root.$refs.loading.start();
          this.$router.push({ name: "MenMyPageRanking" });
          this.$root.$refs.loading.finish();
          break;
        case 5:
          this.$root.$refs.loading.start();
          this.$router.push({ name: "MenMyPageTracks" });
          this.$root.$refs.loading.finish();
          break;
        case 6:
          await this.$router.push({ name: "Favorite" });
          this.$toast(
            "写真の☆をタップするとこちらに追加されます",
            "通知",
            "success"
          );
          break;
        case 7:
          await this.$store
            .dispatch("userApp/getNumberSuggestUsers")
            .then(res => {
              this.totalUser = res.data.data;
            });
          if (this.totalUser && this.user_info.prefecture_master_id) {
            this.suggest = true;
            localStorage.setItem("showUserSuggest", true);
          } else {
            this.$toast("現在おすすめゴルファーはありません", "通知", "danger");
          }
          break;
      }
    },
    backHome() {
      this.isShowTutorial = false;
    },
    showTutorial() {
      this.isShowTutorial = true;
    },
    nextCastSearch(type) {
      let perpage = 25;
      if (window.innerWidth < 992) {
        perpage = 8;
      }
      const params = {
        page: 1,
        type: type
      };
      this.$root.$refs.loading.start();
      this.$router.push({
        name: "CastSearch",
        query: { page: params.page, type: params.type, perpage: perpage }
      });
      this.$root.$refs.loading.finish();
    },
    changeLikeStatus(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.userId,
          is_favorite: data.isFavorite === 1 ? 0 : 1
        }
      }).then(response => {
        const item = this.usersPickUp.find(
          item => item.user_id === data.userId
        );

        if (response.data.success === true) {
          item.is_favorite = data.isFavorite === 1 ? 0 : 1;
        }
      });
    },
    changeLikeStatusPopular(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.userId,
          is_favorite: data.isFavorite >= 1 ? 0 : 1
        }
      }).then(response => {
        const item = this.usersPopular.find(
          item => item.user_id === data.userId
        );
        if (response.data.success === true) {
          item.is_favorite = data.isFavorite === 1 ? 0 : 1;
        }
      });
    },
    redirectToMessageGroup() {
      const roomId = localStorage.getItem("ad_room_" + this.user_info.user_id);
      localStorage.removeItem("ad_room_" + this.user_info.user_id);
      this.$router.push({ name: "MessageDetail", params: { id: roomId } });
    },
    handlePageClick(page) {
      this.$root.$refs.loading.start();
      this.currentPage = page;
      this.getListUsersPopular(page);
      document.getElementById("popular-m-user").scrollIntoView();
      this.$root.$refs.loading.finish();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/home.scss";
@import "~@/assets/scss/_fontFamily.scss";

.invite {
  margin: 25px 0 20px 0;
  &__img {
    width: 100%;
    margin-bottom: 15px;
  }
  &__p {
    font-size: 16px;
    &__highlight {
      margin-bottom: 7px;
      color: #f3705a;
    }
  }
  &__button {
    margin-top: 35px;
    padding-top: 14px;
    padding-bottom: 11px;
    width: 100%;
    background-color: #464d77;
    border-radius: 5px;
    color: #ffff;
  }
}
#home {
  /deep/ .app-body._header .app-container__content {
    background-color: #ffffff;
  }

  .recommend {
    justify-content: center !important;
  }

  .recommend__option {
    display: inline-block !important;
  }
}
.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding: 57px 0 40px 0;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   // height: calc(100vh - 152px);
  // }
  .paginate-popular {
    margin-bottom: 70px !important;
    margin-top: -100px !important;
  }
}
@media only screen and (max-width: 376px) {
  .app-modal.active {
    overflow: auto;
    padding-top: 70px;
  }
}

@media screen and (max-width: 1200px) {
  .paginate-popular {
    margin-bottom: 40px !important;
    margin-top: -80px !important;
  }
}
</style>
